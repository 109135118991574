<template>
  <div class="login">
    <!-- Elementos ocultos para precargar las imágenes -->
    <img src="../assets/img/background.jpg" style="display: none;" />
    <div class="login-container">
      <p class="login-title">Logueate y gestioná tus herramientas BASF</p>
      <div class="login-row">
        <label class="login-label">Correo</label>
        <label v-show="errorEmail" class="login-error">Ingresa mail aquí</label>
        <input
          class="login-input"
          type="text"
          placeholder="Escribe tu usuario aquí"
          ref="loginUser"
          v-model="correo"
          v-bind:class="{ 'error-input': errorUsuario }"
        />
      </div>
      <div class="login-row">
        <label class="login-label">Contraseña</label>
        <input
          class="login-input"
          type="password"
          ref="loginPassword"
          v-on:keyup.enter="handleLogin()"
          v-bind:class="{ 'error-input': errorPassword }"
        />
        <img
          class="login-input-ojo"
          src="../assets/img/visibility_off.png"
          v-show="!mostrarOjoActivo"
          @click="changePasswordType('text')"
        />
        <img
          class="login-input-ojo"
          src="../assets/img/visibility.png"
          v-show="mostrarOjoActivo"
          @click="changePasswordType('password')"
        />
        <div class="login-olvide">
          <label class="login-olvide-label" @click="abrirOlvidePassword()"
            >Olvide mi contraseña</label
          >
        </div>
      </div>
      <div class="login-row login-row-bottom">
        <button v-show="!ingresando" class="login-btn" @click="handleLogin()">
          Ingresar
        </button>
        <button v-show="ingresando" class="login-btn login-btn-ok">
          Ingresando
        </button>
      </div>
      <div class="advise-sign">
        <img
          class="login-icon"
          src="../assets/img/login/login.png"
          alt="login icon"
        />
        <p style="margin: 0;">
          Si es tu primera vez en la plataforma, hacé click sobre
          <strong>Olvidé mi contraseña</strong>
          y verificá tu mail.
        </p>
      </div>
    </div>
    <div class="login-right">
      <div class="login-logo-container">
        <img class="login-logo" src="../assets/img/logo.svg" />
      </div>
      <div v-show="$dev == true" class="dev-container">
        <p>Entorno de desarrollo</p>
      </div>
    </div>
    <transition name="fade-fast">
      <div class="app-popup-container" v-show="mostrarOlvidar">
        <div class="app-popup-mask" @click="mostrarOlvidar = false"></div>
        <div class="app-popup password-guardar-popup">
          <img
            class="password-guardar-close"
            src="../assets/img/close.png"
            @click="mostrarOlvidar = false"
          />
          <div>
            <p class="app-popup-title login-popup-title">
              Recupero de contraseña
            </p>
            <p class="app-popup-text">
              Enviaremos un mail para recupero de contraseña a
              <span>{{ correo }}</span>
            </p>
            <p class="app-popup-subtext">
              Recordá que solo tiene validez el último recupero de contraseña
              generado
            </p>
          </div>
          <div class="password-popup-finalizar-btn-container">
            <button class="login-btn-aceptar" @click="olvidePassword()">
              Enviar ahora
            </button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade-fast">
      <div class="app-popup-container" v-show="mostrarCooldown">
        <div class="app-popup-mask" @click="mostrarCooldown = false"></div>
        <div class="app-popup password-guardar-popup">
          <img
            class="password-guardar-close"
            src="../assets/img/close.png"
            @click="mostrarCooldown = false"
          />
          <div>
            <p class="app-popup-title login-popup-title">
              Recupero de contraseña
            </p>
            <p class="app-popup-text">
              Enviamos un mail a
              <span>{{ correo }}</span> hace menos de 10 minutos.
            </p>
            <p class="app-popup-subtext">
              Recordá revisar la bandeja SPAM o PROMOCIONES.
            </p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade-fast">
      <div class="password-enviado-popup" v-show="mostrarMailEnviado">
        <img class="password-enviado-img" src="../assets/img/login/email.png" />
        <div>
          <p class="app-popup-text">¡Mail enviado correctamente!</p>
          <p class="app-popup-subtext">
            En caso de no haberlo recibido en la bandeja de mails PRINCIPAL,
            revisa SPAM o PROMOCIONES
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      mostrarOjoActivo: false,
      errorUsuario: false,
      errorEmail: false,
      errorPassword: false,
      mostrarOlvidar: false,
      mostrarCooldown: false,
      mostrarMailEnviado: false,
      ingresando: false,
      correo: ""
    };
  },
  created() {},
  mounted() {
    this.$refs.loginUser.value = localStorage.getItem("usuario");
    this.correo = localStorage.getItem("usuario");
    localStorage.removeItem("token-landing");
    this.$store.commit("setReemplazante", null);
    localStorage.removeItem("reemplazo-id");
  },
  methods: {
    changePasswordType(tipo) {
      if (tipo == "text") {
        this.mostrarOjoActivo = true;
      } else {
        this.mostrarOjoActivo = false;
      }
      this.$refs.loginPassword.type = tipo;
    },
    handleLogin() {
      let guardar = true;

      let username = this.$refs.loginUser.value;
      let password = this.$refs.loginPassword.value;

      localStorage.setItem("usuario", username);

      if (username == "" || !this.validateEmail(username)) {
        guardar = false;
        this.errorUsuario = true;
        this.errorEmail = true;
      } else {
        this.errorUsuario = false;
        this.errorEmail = false;
      }
      if (password == "") {
        guardar = false;
        this.errorPassword = true;
      } else {
        this.errorPassword = false;
      }
      if (guardar == true) {
        this.doLogin(username, password);
      }
    },
    async doLogin(email, password) {
      try {
        this.ingresando = true;
        await this.$authService.login(email, password);
        const user = await this.$authService.getCurrentUser();
        localStorage.setItem("user-id", user.id);
        this.getReemplazos();
      } catch (error) {
        this.ingresando = false;
        this.errorUsuario = true;
        this.errorPassword = true;
        console.error('Login failed', error);
      }
    },
    getReemplazos() {
      const id = localStorage.getItem("user-id");

      let that = this;
      this.$axios
        .get("/api/reemplazos/" + id)
        .then(function(response) {
          const reemplazos = response.data;

          that.$store.commit("setReemplazos", reemplazos);

          if (!reemplazos || reemplazos.length === 0) {
            that.$eventHub.$emit("loged");
            that.ingresando = false;
            that.$router.push("/");
          } else {
            that.$eventHub.$emit("loged");
            that.ingresando = false;
            that.$router.push("/seleccionarCuenta");
          }
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    abrirOlvidePassword() {
      let username = this.$refs.loginUser.value;

      if (username == "" || !this.validateEmail(username)) {
        this.errorUsuario = true;
        this.errorEmail = true;
      } else {
        this.errorUsuario = false;
        this.errorEmail = false;

        const that = this;
        this.$axios
          .post("/api/usuario/puedoRecuperar", {
            email: username
          })
          .then(function(response) {
            console.log(response);
            if (response.status == 200) {
              that.mostrarOlvidar = true;
            } else {
              that.errorUsuario = true;
            }
          })
          .catch(function(error) {
            console.log(error);
            if (error.response.status == 404) {
              that.errorUsuario = true;
            } else if (error.response.status == 403) {
              that.mostrarCooldown = true;
            } else if (error.response.status == 401) {
              that.$eventHub.$emit("snackbar", "Usuario no válido");
            }
          });
      }
    },
    olvidePassword() {
      this.mostrarOlvidar = false;
      let guardar = true;
      let username = this.$refs.loginUser.value;

      if (username == "") {
        guardar = false;
        this.errorUsuario = true;
        this.errorEmail = true;
      } else {
        this.errorUsuario = false;
        this.errorEmail = false;
      }
      if (guardar == true) {
        const that = this;
        this.$axios
          .post("/api/usuario/olvidar", { email: username })
          .then(function(response) {
            if (response.status == 200) {
              that.mostrarMailEnviado = true;
            }
          })
          .catch(function(error) {
            console.log(error);
            if (error.response.status == 404) {
              that.errorUsuario = true;
            }
          });
      }
    },
    chequearEmail() {
      let username = this.$refs.loginUser.value;
      if (
        this.validateEmail(username) &&
        !username.split("@")[1].includes("basf")
      ) {
        this.mostrarSoldTo = true;
      } else {
        this.mostrarSoldTo = false;
      }
    },
    validateEmail(email) {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style>
@import "../assets/css/views/login.css";
</style>
